<template>
  <UserSearchResultCard
    to="specialist-profile"
    :data="response.responded"
    :skills="response.responded.specialist_skills"
    price-title="Ставка в час"
    :price="response.responded.specialist_price"
    :is-new="!response.is_view"
  >
    <template #default>
      <ResponseVacancies :vacancies="response.vacancies" :invitation="isRequest" />
    </template>
    <template #action>
      <BaseButton
        v-if="!response.contacts"
        class="select-btn"
        :class="{ pending }"
        color-primary
        @click.stop="requestContacts"
        :done="actionDone"
      >
        <ContactIcons :keys="response.responded.available_contacts" />
        <span>{{ actionText }}</span>
        <i v-if="!actionDone">{{ response.contacts_price }}₽</i>
      </BaseButton>
      <ProfileContacts v-else :contacts="response.contacts" />
    </template>
  </UserSearchResultCard>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import { acceptProjectResponse, openProjectRequest } from '~/api/projects'

const props = defineProps({
  response: Object
})
const emit = defineEmits(['update'])

const router = useRouter()

const user = inject('user')
const prompt = ref()
const updateUserInfo = inject('updateUserInfo')

const isRequest = computed(() => props.response.direction === 'to_spec')
const actionText = computed(() => ({
  'new': 'Показать контакты',
  'request': 'Контакты запрошены',
  'specialist-accepted': 'Показать контакты',
  'specialist-declined': 'Запрос отклонён'
})[props.response.status])
const actionDone = computed(() => ['request', 'specialist-declined'].includes(props.response.status))

const pending = ref(false)

async function requestContacts () {
  if (props.response.contacts_price > user.value.coins_total) {
    if (
      await prompt.value({
        title: `У вас недостаточно средств на балансе, чтобы получить контакт`,
        description: `Стоимость одного контакта – ${props.response.contacts_price}₽`,
        accept: 'Пополнить баланс'
      })
    ) await router.push({ name: 'pay-form' })
    return
  }

  pending.value = true
  try {
    emit('update', await (isRequest.value ? openProjectRequest : acceptProjectResponse)(props.response.id))
    updateUserInfo()
  } catch (e) {}
  finally {
    pending.value = false
  }
}
</script>

<style scoped lang="scss">
i {
  font-style: inherit;
  font-weight: 500;
}
</style>

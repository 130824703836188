<template>
  <div class="project-responses" :class="{ pending }">
    <ChipList
      class="project-responses__filter"
      scrollable
      select
      :items="filterOptions"
      v-model="filter"
    />
    <div class="project-responses__list">
      <ProjectResponseSearchResultCard
        v-for="response in responses"
        :key="response.id"
        :response="response"
        @update="response.contacts = $event?.contacts"
      />

      <div class="no-items" v-if="pagination?.total === 0">
        <div class="title">Отклики отсутствуют</div>
        <BaseButton v-if="filter !== filterOptions[0].id" color-primary big @click="filter = filterOptions[0].id">Показать все</BaseButton>
      </div>
    </div>
    <BasePagination class="project-responses__pagination" :pagination="pagination" />
  </div>
</template>

<script setup>
import { getProjectResponses, readAllProjectResponses } from '~/api/projects'
import usePagination from '~/composables/usePagination'
import useIsMounted from '~/composables/useIsMounted'

const props = defineProps({
  project: Object
})
const emit = defineEmits(['read-responses'])

const router = useRouter()
const route = useRoute()

const filterOptions = [
  { id: 'all', name: 'Все' },
  { id: 'new', name: 'Новые' },
  { id: 'accepted', name: 'Контакты получены' },
]
const filter = ref(route.query?.filter ?? filterOptions[0].id)
const isMounted = useIsMounted()

const {
  loading: pending,
  pagination,
  page,
  items: responses,
  load,
  error
} = await usePagination(
  (page, perPage) => {
    const data = {
      page,
      filter: filter.value
    }
    if (isMounted.value
      || route.query.page
      || filter.value !== filterOptions[0].id)
      if (isMounted.value) router.replace({ query: data })

    return getProjectResponses(props.project.id, {
      ...data,
      per_page: perPage
    })
  },
  ref(15)
)
if (error.value?.statusCode === 403) throw createForbiddenError()
if (!responses.value) throw createNotFoundError()

const { start: readAll } = useTimeoutFn(() => {
  readAllProjectResponses(props.project.id)
  emit('read-responses')
}, 2000, { immediate: false })
watchEffect(() => process.client && props.project.new_responses_count > 0 && readAll())

watch(filter, load)
</script>

<style scoped lang="scss">
.project-responses {
  @media (max-width: 920px) {
    padding-bottom: 8px;
    margin-left: -15px;
    width: calc(100% + 30px);
    background: var(--color-bg-secondary);
  }

  &__filter {
    margin-bottom: 32px;
    background: var(--color-bg);

    @media (max-width: 920px) {
      margin-bottom: 8px;
      padding: 0 15px 15px;
    }
  }
  &__pagination {
    padding: 0;
    margin-top: 32px;

    @media (max-width: 920px) {
      margin-top: 0;
      background: var(--color-bg);
      padding: 0 15px;
      :deep(.btn-page) {
        height: 56px;
      }
    }
  }
}
</style>
